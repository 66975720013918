
const PlusIcon = ({ color = '#d2233c', size = 18 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="7.80475" y="0.945679" width="2.39666" height="16.7766" rx="1.19833" fill={color}/>
            <rect x="0.614624" y="10.5323" width="2.39666" height="16.7766" rx="1.19833" transform="rotate(-90 0.614624 10.5323)" fill={color}/>
        </svg>
    )
}

export default PlusIcon