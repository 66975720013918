import { API, BASE_DOMAIN } from '@/api'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { useProject } from '@/hooks/useProject'
import useResponsive from '@/hooks/useResponsive'
import { translate } from '@/i18n'
import { useGlobalStore } from '@/stores/globalStore'
import { useUserStore } from '@/stores/userStore'
import Avatar from '@/ui/components/Avatar'
import { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const User = () => {
	const navigate = useNavigate()

	const { workspaceId, projectId } = useProject()
	const dropdownRef = useRef(null)
	const { isDesktop } = useResponsive()

	// local state
	const [isOpen, setOpen] = useState(false)

	// data selectors
	const setEmployee = useGlobalStore((state) => state.setEmployee)
	const isAuthenticated = useUserStore((state) => state.isAuthenticated)
	const user = useUserStore((state) => state.user)
	// const setUser = useUserStore(state => state.setUser)
	// const setAuthenticated = useUserStore(state => state.setAuthenticated)
	const apiUrl = useSettingsSelector(
		(settings) => settings.api.url,
		BASE_DOMAIN,
	)
	useOnClickOutside(dropdownRef, () => setOpen(false))

	const handleLogout = () => {
		// localStorage.removeItem('token')
		// setUser(null)
		// setAuthenticated(false)
		// queryClient.clear()
		navigate(`/logout`)
	}

	const handleProfileOpen = () => {
		if (user) {
			setEmployee(user.user_id)
		}
		setOpen(false)
	}

	if (!isAuthenticated || !user)
		return (
			<LoginLink
				to={`/login?workspace_id=${workspaceId}&project_id=${projectId}`}
			>
				{translate('log-in')}
			</LoginLink>
		)

	return (
		<UserBarWrapper ref={dropdownRef}>
			<UserContainer>
				<CurrentUser onClick={() => setOpen(!isOpen)}>
					<Avatar
						src={API.user.getAvatar({
							userId: Number(user.user_id),
							url: apiUrl,
						})}
					/>
					{isDesktop && <Name>{user.display}</Name>}
				</CurrentUser>
			</UserContainer>
			{isOpen && (
				<Dropdown>
					<Avatar
						size="big"
						src={API.user.getAvatar({
							url: apiUrl,
							userId: Number(user.user_id),
						})}
					/>
					<UserInfo>
						<DropdownName>{user.display}</DropdownName>
						<ul>
							<li onClick={handleProfileOpen}>
								<ProfileButton href="#">{translate('profile')}</ProfileButton>
							</li>
							{/*<li onClick={handleLogout}>*/}
							{/*    <Link to="/logout">*/}
							{/*        <UserDropdownButton href="/logout">*/}
							{/*            {translate('log-out')}*/}
							{/*        </UserDropdownButton>*/}
							{/*    </Link>*/}
							{/*</li>*/}
						</ul>
					</UserInfo>
				</Dropdown>
			)}
		</UserBarWrapper>
	)
}

export default User

const DropdownName = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    margin-bottom: 6px;
`

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Dropdown = styled.div`
    position: absolute;
    top: 75px;
    right: 0px;
    max-width: 240px;
    width: 100%;
    border-radius: 0 0 6px 6px;
    padding: 10px;
    display: grid;
    grid-template-columns: 60px 1fr;
    column-gap: 10px;
    box-shadow: 0px 0px 40px rgba(84, 84, 84, 0.25);
    background: #d2233c;

    ul {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    a {
        text-decoration: none;
    }
`

const UserDropdownButton = styled.a`
    background: #FFFFFF;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    display: flex;
    padding: 0 5px;
    font-size: 12px;
    line-height: 24px;
    color: #000000;

    &:hover {
        opacity: 0.7;
    }
`

const ProfileButton = styled(UserDropdownButton)`
    background: #2C2C2C;
    color: #fff;
`

const CurrentUser = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`

const LoginLink = styled(Link)`
    color: ${(props) => props.theme.palette.text.label};
    text-decoration: none;
    text-align: right;
`

const UserBarWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
`

const UserContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
`

const Name = styled.div`
    margin-left: 1rem;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    max-width: 250px;
`
