import { useBookingsForLayer } from '@/api/hooks/useBookingsForLayer'
import { useLayerView } from '@/api/hooks/useLayerView'
import { useNodes } from '@/api/hooks/useNodes'
import { useTree } from '@/api/hooks/useTree'
import CloseIcon from '@/components/icons/CloseIcon'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { useGlobalStore } from '@/stores/globalStore'
import { useProjectStore } from '@/stores/projectStore'
import { formatLocalDateToAPI } from '@/utils/helpers/dates.helpers'
import uniqueBy from '@popperjs/core/lib/utils/uniqueBy'
import { addMinutes } from 'date-fns'
import { useMemo, useRef } from 'react'
import styled from 'styled-components'
import Count from './Count'
import Row from './Row'

const getAllIdsWithOwnViewTrue = (node: any): number[] => {
  if (!node) return []
  let ids: number[] = []

  if (node.own_view) {
    ids.push(node.id)
  }

  if (node.childs && node.childs.length > 0) {
    for (const childNode of node.childs) {
      ids = ids.concat(getAllIdsWithOwnViewTrue(childNode))
    }
  }

  return ids
}

const useRootLayerStats = (layerId: number | null) => {
  const { layers, isLoading: isTreeLoading } = useTree()
  const { data, isLoading: isNodesLoading } = useNodes({
    page: 0,
    perPage: 5_000,
    bookable: 1
  })
  const rootNode = layers?.tree.find((l) => l.id == layerId) || {}
  const ids: number[] = getAllIdsWithOwnViewTrue(rootNode)

  const { layerView, isLoading: isLoadingView } = useLayerView(Number(layerId))

  const layerName = useMemo(
    () => layerView?.view.map_node.name || '',
    [layerView?.view]
  )

  const nodesCount = useMemo(
    () =>
      data?.items.filter((item) => ids.includes(Number(item.parent))).length ||
      0,
    [data?.items]
  )

  const { bookings, isLoading } = useMultipleLayerStats(ids)

  const bookingsCount: any = useMemo(
    () => bookings.reduce((acc, val) => acc + val, 0),
    [bookings, isLoading]
  )

  return {
    data: {
      total: nodesCount,
      free: nodesCount - bookingsCount,
      busy: bookingsCount,
      name: layerName
    },
    isLoading: isTreeLoading || isNodesLoading || isLoadingView
  }
}

const useMultipleLayerStats = (layerIds: number[]) => {
  const queries = layerIds.map((layerId) => useSingleLayerStats(layerId))
  const isLoading = queries.some((query) => query.isLoading)

  const bookings = queries.map((query) => query.busy || 0)
  const bookingsData = queries.map((query) => query.bookings || [])
  const bookingsUniqueCount = uniqueBy(uniqueBy(bookingsData?.flat() || [], (b) => b.point_id) || [], (b) => b.booking_id).length || 0
  return {
    bookings: [bookingsUniqueCount],
    isLoading
  }
}

const getBookableCount = (arr: any[] | undefined) => {
  if (!arr || !arr.length) return 0
  return arr.reduce((acc, node) => {
    const plugins: any[] = Object.values(node.plugin_data)
    const isBookable = plugins.find((d) => d.bookable)
    if (!isBookable) return acc

    return acc + 1
  }, 0)
}

const useSingleLayerStats = (layerId: number | null) => {
  const selection = useGlobalStore((state) => state.selection)
  const { data, isLoading: isLoadingBookings } = useBookingsForLayer(
    Number(layerId),
    formatLocalDateToAPI(selection.startDate),
    formatLocalDateToAPI(addMinutes(selection.endDate, -30))
  )

  return {
    total: 0,
    busy: uniqueBy(data?.bookings || [], (b) => b.point_id).length || 0,
    bookings: data?.bookings,
    isLoading: isLoadingBookings
  }
}

const useBookingStats = () => {
  const activeLayer = useGlobalStore((state) => state.activeLayer)
  const { layerView, isLoading: isLoadingView } = useLayerView(
    Number(activeLayer)
  )
  const bookableObjectsCount: any = useMemo(
    () => getBookableCount(layerView?.points),
    [layerView?.points]
  )

  const layerName = useMemo(
    () => layerView?.view.map_node.name || '',
    [layerView?.view]
  )

  const stats = useSingleLayerStats(activeLayer)

  return {
    data: {
      total: bookableObjectsCount,
      busy: stats.busy,
      free: bookableObjectsCount - stats.busy,
      name: layerName
    },
    isLoading: stats.isLoading || isLoadingView
  }
}

const BookingStatisticsPopup = ({ close, layer }: { layer: number, close?: () => void }) => {
  const ref = useRef<HTMLDivElement>(null)
  const nodes = useProjectStore((state) => state.nodes)

  const isRootLayer = useMemo(() => {
    const currentNode = nodes.find((n) => n.id === layer)
    return currentNode && currentNode.ownView && !currentNode.parent
  }, [nodes, layer])

  useOnClickOutside(ref, close)

  const renderStats = () => {
    if (isRootLayer) return <RootBookingStatisticsPopup layerId={layer} />
    return <SingleBookingStatisticsPopup />
  }

  return (
    <BookingStatisticsPopupWrapper ref={ref}>
      <Close onClick={close}>
        <CloseIcon color="#000" />
      </Close>
      {renderStats()}
    </BookingStatisticsPopupWrapper>
  )
}

const SingleBookingStatisticsPopup = () => {
  const { data, isLoading } = useBookingStats()

  if (isLoading) return <Message text="Загрузка..." />
  if (data)
    return (
      <Content
        name={data.name}
        free={data.free}
        busy={data.busy}
        total={data.total}
      />
    )

  return <Message text="Данные отсутствуют" />
}

const RootBookingStatisticsPopup = ({ layerId }) => {
  const { data, isLoading } = useRootLayerStats(layerId)

  if (isLoading) return <Message text="Загрузка..." />
  if (data)
    return (
      <Content
        name={data.name}
        free={data.free}
        busy={data.busy}
        total={data.total}
      />
    )

  return <Message text="Данные отсутствуют" />
}

const Message = ({ text }: { text: string }) => <Title>{text}</Title>

const Content = ({
  name,
  ...rowProps
}: {
  name: string
  free: number
  busy: number
  total: number
}) => (
  <>
    <Title>{name}</Title>
    {/* <SectionTitle>Бронируемые</SectionTitle> */}
    <Row name="Рабочее место" {...rowProps} />
    <Help>Подсказка*</Help>
    <Count free="Свободные" busy="Занятые" total="Итого" />
  </>
)

const BookingStatisticsPopupWrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  margin-right: 26px;
  box-shadow: 4px 8px 8px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 6px 10px 12px;
  min-width: 220px;
  max-width: 500px;
  width: 100%;
`

const Close = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`

const Title = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  margin: 1.3rem 0;
  color: #000000;
`
const SectionTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0.7rem 0;
  color: #1e22aa;
`

const Help = styled.div`
  font-size: 1.1rem;
  font-weight: 300;
  margin: 1.4rem 0 0.7rem;
`

export default BookingStatisticsPopup
