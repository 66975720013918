import ArrowIcon from '@/components/icons/ArrowIcon'
import { translate } from '@/i18n'
import { useBookingStore } from '@/stores/bookingStore'
import media from '@/ui/media'
import { addWeeks, eachWeekOfInterval, endOfWeek, format, startOfWeek } from 'date-fns'
import { useFormikContext } from "formik"
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'

type WeekSelectorProps = {
    visible?: boolean
}

const WeekSelector: React.FC<WeekSelectorProps> = ({  visible = true }) => {
    const { week, setWeek } = useBookingStore()
    const { values } = useFormikContext<any>()
    const { type, start, end } = values

    const weekStart = startOfWeek(week, { weekStartsOn: 1 })
    const weekEnd = endOfWeek(week, { weekStartsOn: 1 })

    // if (!visible) return null

    useEffect(() => {
        if (type === '2' && start && end) {
            const weeks = eachWeekOfInterval({ start, end }, { weekStartsOn: 1 })
            const weekToSet = weeks[1] || weeks[0]

            setWeek(weekToSet)
        }
    }, [type, start, end])

    return (
        <Wrapper>
            <Button prev onClick={() => setWeek(addWeeks(week, -1))} />
            <WeekText>
                {translate('show-bookings-between', { start: format(weekStart, 'dd.MM.yyyy'), end: format(weekEnd, 'dd.MM.yyyy') })}
            </WeekText>
            <Button next onClick={() => setWeek(addWeeks(week, 1))} />
        </Wrapper>
    )
}

export default WeekSelector

const Button: React.FC<{ next?: boolean, prev?: boolean } & React.HTMLAttributes<HTMLButtonElement>> = ({ next, prev, ...otherProps }) => {
    return (
        <ButtonBase
            type="button"
            $prev={prev}
            $next={next}
            {...otherProps}
        >
            <ArrowIcon />
        </ButtonBase>
    )
}

const ButtonBase = styled.button<{ $next?: boolean, $prev?: boolean }>`
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    background: #d2233c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;

    ${({ $next }) => $next && css`
        transform: rotate(180deg);
    `}

    ${({ $prev }) => $prev && css`
        transform: rotate(0deg);
    `}
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const WeekText = styled.div`
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    padding: 0 1rem;

    ${media.md`
        font-size: 14px;
        line-height: 18px;
    `}
`